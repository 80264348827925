@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
body {
  background: #8e7e70;
  background: -webkit-linear-gradient(to right, #8e7e70, #5c4b3e);
  background: linear-gradient(to right, #8e7e70, #5c4b3e);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  display: flex;
}

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #fbbf24;
}
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
  color: #92b4d8;
  padding-left: 15px;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: #8e7e70;
  background: -webkit-linear-gradient(to right, #8e7e70, #5c4b3e);
  background: linear-gradient(to right, #8e7e70, #5c4b3e);
  border: 2px solid #c7b198;
  border-radius: 10px;
  padding: 2rem 3rem;
  position: relative;
  color: #ffffff; /* Set text color for better visibility */
}

.cityName {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cityName > p {
  position: relative;
}
.cityName > p img {
  width: 20px;
  position: absolute;
}

.currtemp {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: bold;
}

.forecastdata {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
  scroll-behavior: smooth;
}
.windData {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  gap: 1rem;
}
.windData span {
  color: #fbbf24;
}
.currtemp div:nth-child(2) {
  font-size: 4rem;
}
.currtemp div:nth-child(2) > p {
  font-size: 1.7rem;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  width: 300px;
  border-radius: 20px;
  padding: 0 1rem;
}
input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 90%;
  padding: 0.7rem 0.5rem;
  font-size: 1.2rem;
  color: #17384c;
}
.weatherData {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}
.invalid {
  color: #ff4b4b;
  margin-top: 1rem;
}
.translater {
  position: absolute;
  left: 51%;
  top: 7%;
  cursor: pointer;
  background-color: #dfdfdfe1;
  font-size: 1.4rem;
  padding: 0.3rem 0.7rem;
  border-radius: 10px;
  color: #17384c;
}
.translater:hover {
  background-color: #608094;
  color: white;
}
.translater:hover {
  filter: contrast(100%);
}
.forecastdata div:nth-child(n + 2) {
  border-left: 2px solid rgba(255, 255, 255, 0.166);
}
.forecastdata div {
  padding: 0 2rem;
}
.tempAndLogo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 63%;
}
.rightarrow {
  position: absolute;
  font-size: 2rem;
  bottom: 29%;
  right: 0%;
}
.leftarrow {
  position: absolute;
  font-size: 2rem;
  bottom: 29%;
  left: 0%;
}
.copyright {
  font-size: 0.8rem;
}

@media only screen and (max-width: 880px) {
  .cityName {
    flex-direction: column;
    gap: 1rem;
  }
  .App {
    align-items: initial;
  }
  .translater {
    left: 0;
    top: 2%;
  }
  .currtemp {
    flex-direction: column;
    gap: 5rem;
    justify-content: center;
  }
  .weatherData {
    align-items: center;
    justify-content: initial;
    height: fit-content;
  }
  .currtemp div:nth-child(2) {
    width: 250px;
    text-align: center;
    font-size: 2rem;
  }
  .forecastdata {
    margin-top: 2rem;
    width: 300px;
    overflow-x: scroll;
    border-left: 2px solid #608094;
    border-right: 2px solid #608094;
  }
  .forecastdata::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .forecastdata div:nth-child(n + 2) {
    border-left: none;
  }
  .forecastdata div {
    padding: 0 1.5rem;
  }
  .box {
    width: fit-content;
    padding: 1rem;
    border: none;
    background: none;
    height: auto;
    justify-content: center;
  }
  body {
    background-image: none;
    background: #8e7e70;
    background: -webkit-linear-gradient(to left, #8e7e70, #5c4b3e);
    background: linear-gradient(to top, #8e7e70, #5c4b3e);
    color: #ffffff; /* Set text color for better visibility */
  }

  .tempAndLogo img {
    padding: 0;
    margin: 0;
    width: 130px;
  }
  .currtemp div {
    padding: 0;
  }
  .tempAndLogo {
    display: flex;
    gap: 0;
    justify-content: center;
    align-items: center;
    width: 330px;
    margin-top: 2rem;
    border: 2px solid #608094;
    border-radius: 10px;
  }
  .windData {
    display: none;
  }
}
/* Header.css */

.header {
  background-color: #29323c;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}

.app-title {
  font-size: 24px;
  margin: 0;
}
